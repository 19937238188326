import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500&display=swap');
   
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto' !important;
  }

  * {
        box-sizing:border-box;
    }


  a{
	text-decoration: none;
  }

  .p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
      color: ${(props) => props.theme.secondary} !important
  }

  .p-toast {
      z-index: 9998 !important;
  }


  * {
    scrollbar-width: none;
    scrollbar-color: #442360 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #442360;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .p-datepicker table td > span.p-highlight	{
    background-color: rgba(213,40,151,0.5) !important;
      color:white !important;
  }
`;

export const AppWrapper = styled.div`
	width: 100vw;
	height: 100vh;
`;

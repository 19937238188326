import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import lottie from '../../assets/lotties/loader.json';
const Loader = ({ message }) => {
	return (
		<Background>
			<LottieContainer>
				<Lottie animationData={lottie} loop autoplay width={'25%'} />
			</LottieContainer>
			<MessageLoader>{message}</MessageLoader>
		</Background>
	);
};

export default Loader;

const Background = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${(props) => props.theme.shadow25};
	backdrop-filter: blur(3px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
`;

const MessageLoader = styled.span`
	font-size: 1.5em;
	color: white;
	font-weight: bold;
	text-shadow: 0 0 1em ${(props) => props.theme.shadow50};
`;

const LottieContainer = styled.div`
	width: 30%;
`

;




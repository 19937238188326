import styled from 'styled-components';

export const CalendarioWrapper = styled.div`
	height: 80%;
	width: 100%;
	display: flex;
	margin-top: 3em;
`;

export const CalendarContainer = styled.div`
	height: 100%;
	width: 50%;
	display: flex;
	justify-content: center;
`;

export const EventosListado = styled.div`
	height: 100%;
	width: 50%;
	display: flex;
	justify-content: center;
`;

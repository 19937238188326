import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import Authentication from '../views/Authentication/Authentication';
import Dashboard from '../views/Dashboard/Dashboard';

const RoutesApplication = () => {
	return (
		<Routes>
			<Route index path='/*' element={<Authentication />} />
			<Route exact path='/principal' element={<Navigate to='/principal/expensas' />} />
			<Route path='/principal/*' element={<Dashboard />} />
		</Routes>
	);
};

export default RoutesApplication;

import { PrimeIcons } from 'primereact/api';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/Modal/Modal';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { getMovimientosLiquidacion } from '../../../services/Expensas';
import { FormatDate } from '../../../utils/Functions';
import TablaDetalles from './TablaDetalles';
const ExpensaDetalle = ({ liquidacion }) => {
	const { toast } = useContext(ToastContext);
	const dispatch = useDispatch();
	const [modal, setModal] = useState({
		detalles: [],
		visible: false,
	});

	const obtenerDetallesExpensa = async () => {
		dispatch(startLoading('Obteniendo movimientos de la liquidación'));

		try {
			const { Data, Success } = await getMovimientosLiquidacion(liquidacion.ID_LIQUIDACION);
			if (Success) {
				const DATA_PARSED = JSON.parse(Data);
				let rubrosDetalles = {};
				const rubros_ID = new Set(DATA_PARSED.map((row) => row.Rubro));
				rubros_ID.forEach((rubro) => {
					const MovimientosRubro = DATA_PARSED.filter((row) => row.Rubro === rubro);
					rubrosDetalles[rubro] = MovimientosRubro;
				});
				const rubrosDetallesDesc = {};

				rubros_ID.forEach((ID) => {
					const DescripcionRubro = DATA_PARSED.find((row) => +row.Rubro === +ID)?.Descripcion_Rubro;
					rubrosDetallesDesc[DescripcionRubro] = rubrosDetalles[ID];
				});
				setModal({
					visible: true,
					detalles: rubrosDetallesDesc,
				});
			}
			dispatch(stopLoading());
		} catch (error) {
			dispatch(stopLoading());
		}
	};

	return (
		<DetalleWrapper>
			<Modal
				header='Detalles de expensa'
				width='50vw'
				footer={NaN}
				visible={modal.visible}
				onHide={() => setModal({ visible: false, detalles: [] })}>
				<TablaDetalles detalles={modal.detalles} />
			</Modal>
			<DetalleRow>
				<DetalleLabel>Período</DetalleLabel>
				<DetalleValue>
					{FormatDate(new Date(liquidacion.FechaDesde))} a {FormatDate(new Date(liquidacion.FechaHasta))}
				</DetalleValue>
			</DetalleRow>
			<DetalleRow>
				<DetalleLabel>Vencimiento</DetalleLabel>
				<DetalleValue>{FormatDate(new Date(liquidacion.Vencimiento1))}</DetalleValue>
			</DetalleRow>
			<DetalleRow>
				<DetalleLabel>Saldo anterior</DetalleLabel>
				<DetalleValue>${liquidacion.SaldoAnterior}</DetalleValue>
			</DetalleRow>
			<DetalleRow>
				<DetalleLabel>Saldo a pagar</DetalleLabel>
				<DetalleValue>${liquidacion.SaldoAPagar}</DetalleValue>
			</DetalleRow>

			<ButtonRow>
				<ButtonVEME onClick={obtenerDetallesExpensa} label='Ver movimientos' />
				<IconPDF
					as='a'
					onClick={() => {
						toast.current.show({
							severity: 'info',
							summary: 'La descarga puede tardar unos segundos',
						});
					}}
					className={PrimeIcons.FILE_PDF}
					href={liquidacion.URL_REPORTE}
				/>
			</ButtonRow>
		</DetalleWrapper>
	);
};

export default ExpensaDetalle;

const DetalleWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const DetalleRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-bottom: 0.5em;
`;

const DetalleLabel = styled.span`
	font-size: 1em;
	width: 25%;
	font-weight: bold;
	@media screen and (max-width: 700px) {
		font-size: 0.8em;
		width: 50%;
	}
`;
const DetalleValue = styled.span`
	font-size: 1em;
	width: 25%;
	@media screen and (max-width: 700px) {
		font-size: 0.8em;
		width: 50%;
	}
`;

const ButtonRow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: 2em;
`;

const IconPDF = styled.i`
	font-size: 2em;
	color: ${(props) => props.theme.secondary};
	cursor: pointer;
	margin-left: 2em;
	width: 25%;
	transition: 0.2s;
	&:hover {
		color: ${(props) => props.theme.main};
		transition: 0.2s;
	}
`;

import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import lottie from '../../assets/lotties/empty.json';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`;

const Message = styled.p`
	font-size: 2em;
	color: ${(props) => props.theme.main};
	font-weight: bold;
	border-bottom: 0.15em solid ${(props) => props.theme.secondary50};
	height: fit-content;
	border-radius: 0.1em;
`;

const Empty = ({ message = 'No hay datos' }) => {
	return (
		<Wrapper>
			<Message>{message}</Message>
		</Wrapper>
	);
};

export default Empty;

import React from 'react';
import styled from 'styled-components';

const NovedadWrapper = styled.div`
	width: 100%;
	color: ${(props) => props.theme.main};
`;

const NovedadCuerpo = styled.p``;

const NovedadAdjuntos = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	font-size: 0.85em;
	color: ${(props) => props.theme.secondary};
	cursor: pointer;
	font-weight: bold;
`;

const LinkAdjunto = styled.a`
	color: ${(props) => props.theme.secondary};
	&:hover,
	&:visited,
	&:active,
	&:focus {
		text-decoration: none;
		color: ${(props) => props.theme.main};
	}
`;

const DetalleNovedad = ({ novedad }) => {
	return (
		<NovedadWrapper>
			<NovedadCuerpo>{novedad.cuerpo}</NovedadCuerpo>
			{novedad?.adjuntos?.length > 0 && (
				<NovedadAdjuntos>
					{novedad?.adjuntos.map((adjunto) => (
						<LinkAdjunto target='_blank' href={adjunto.URL_Corta}>
							{adjunto.NombrePublicoArchivo}
						</LinkAdjunto>
					))}
				</NovedadAdjuntos>
			)}
		</NovedadWrapper>
	);
};

export default DetalleNovedad;

import React from 'react';
import styled from 'styled-components';
import { FormatDate } from '../../../utils/Functions';
const HeaderRubro = styled.div`
	width: 100%;
	font-size: 1em;
	border: 1px solid ${(props) => props.theme.main};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: ${(props) => props.theme.main};
	padding: 0.25em;
`;

const HeaderDetalle = styled.div`
	width: 100%;
	font-size: 0.8em;
	padding: 0.15em;
	border: 1px solid ${(props) => props.theme.main};
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: ${(props) => props.theme.main};
	font-weight: bold;
`;

const Container = styled.div`
	width: 100%;
	height: 100%;
	border: 1px solid ${(props) => props.theme.main};
	padding: 0.5em;
`;

const Detalle = styled.div`
	width: 100%;
	font-size: 0.9em;
	padding: 0.25em;
	border: 1px solid ${(props) => props.theme.main};
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: ${(props) => props.theme.main};
`;

const Column = styled.div`
	width: ${(props) => props.width || '20%'};
	padding: 0.25em;
	font-size: 0.85em;
`;

const TablaDetalles = ({ detalles }) => {
	return (
		<Container>
			{Object.keys(detalles).map((key) => {
				const detalle = detalles[key];
				return (
					<>
						<HeaderRubro>{key}</HeaderRubro>
						<HeaderDetalle>
							<Column width='15%'>Fecha</Column>
							<Column width='30%'>Descripcion</Column>
							<Column width='20%'>Proveedor</Column>
							<Column width='10%'>Monto</Column>
							<Column width='10%'>Comprobante</Column>
						</HeaderDetalle>
						{detalle.map((row) => (
							<Detalle>
								<Column width='15%'>{FormatDate(new Date(row.Fecha))}</Column>
								<Column width='30%'>{row.descripcion}</Column>
								<Column width='20%'>{row.PROVEEDOR_RAZON_SOCIAL}</Column>
								<Column width='10%'>${row.monto}</Column>
								<Column width='10%'>{row.nroComprobante}</Column>
							</Detalle>
						))}
					</>
				);
			})}
		</Container>
	);
};

export default TablaDetalles;

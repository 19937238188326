import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputVEME from '../../../components/InputVEME/InputVEME';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { obtenerEventosCalendario } from '../../../services/Calendario';
import EventoDetalle from './EventoDetalle';
import { CalendarContainer, CalendarioWrapper, EventosListado } from './styles';
import Empty from '../../../components/Empty/Empty';

const Calendario = () => {
	const { toast } = useContext(ToastContext);
	const { consorcio } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [eventos, setEventos] = useState([]);

	const [fecha, setFecha] = useState(new Date());
	const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());

	const ListarEventos = async () => {
		dispatch(startLoading('Obteniendo eventos'));
		const mes = fecha.getMonth() + 1;
		const anio = fecha.getFullYear();

		try {
			const { Data, Success } = await obtenerEventosCalendario({
				consorcio,
				mes,
				anio,
			});
			if (Success) {
				const formatted = Data.map(({ fecha, titulo, id, descripcion, hora, tipo }) => {
					const FECHA = new Date(`${fecha.split('/').reverse().join('-')}T${hora}:00.000Z`);
					FECHA.setHours(FECHA.getHours() + 3);
					return {
						fechaCompleta: FECHA,
						fecha,
						titulo,
						id,
						descripcion,
						tipo,
						hora,
					};
				});
				setEventos(formatted);
			}
			dispatch(stopLoading());
		} catch (error) {
			dispatch(stopLoading());
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al obtener los eventos',
			});
		}
	};

	useEffect(() => {
		ListarEventos();
	}, []);

	useEffect(() => {
		ListarEventos();
	}, [fecha, consorcio]);

	return (
		<CalendarioWrapper>
			<CalendarContainer>
				<InputVEME
					isCalendar
					selectionMode='multiple'
					yearRange={`${new Date().getFullYear() - 2}:${new Date().getFullYear() + 1}`}
					monthNavigator
					yearNavigator
					inline
					viewDate={fecha}
					onViewDateChange={(e) => setFecha(e.value)}
					value={eventos.map((evento) => evento.fechaCompleta)}
				/>
			</CalendarContainer>
			<EventosListado>
				{eventos.length ? (
					eventos.map((evento) => <EventoDetalle evento={evento} />)
				) : (
					<Empty message='No hay eventos para mostrar' />
				)}
			</EventosListado>
		</CalendarioWrapper>
	);
};

export default Calendario;

import styled from 'styled-components';

export const AdministracionWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 1em;
`;

export const NombreAdministracion = styled.span`
	font-size: 1.5em;
	font-weight: 500;
	color: ${(props) => props.theme.main};
	border-bottom: 2px solid ${(props) => props.theme.secondary};
`;

export const Label = styled.label`
	font-size: 1.05em;
	color: ${(props) => props.theme.main};
	width: 45%;
	@media screen and (max-width: 700px) {
		width: 50%;
	}
`;
export const Value = styled.span`
	font-size: 1.25em;
	color: ${(props) => props.theme.main};
	font-weight: bold;
	width: 45%;
	@media screen and (max-width: 700px) {
		width: 50%;
		font-size: 1em
	}
`;

export const RowAdministracion = styled.div`
	width: 50%;
	display: flex;
	justify-content: space-around;
	margin-top: 2em;
	align-items: center;
	@media screen and (max-width: 700px) {
		width: 90%;
	}
`;

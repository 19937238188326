import { Accordion, AccordionTab } from 'primereact/accordion';
import { PrimeIcons } from 'primereact/api';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Empty from '../../../components/Empty/Empty';
import Icon from '../../../components/Icon/Icon';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { marcarNovedadLeida, obtenerNovedades } from '../../../services/Novedades';
import DetalleNovedad from './DetalleNovedad';
import { NovedadesWrapper } from './styles';

const Novedades = () => {
	const { toast } = useContext(ToastContext);
	const dispatch = useDispatch();
	const { consorcio } = useSelector((state) => state.auth);
	const [novedades, setNovedades] = useState([]);

	const ESTADOS = {
		SI: true,
		NO: false,
	};

	const ListarNovedades = async () => {
		try {
			dispatch(startLoading('Obteniendo listado de novedades'));
			const { Data, Success } = await obtenerNovedades(consorcio);
			if (Success) {
				setNovedades(Data);
			}
			dispatch(stopLoading());
		} catch (error) {
			dispatch(stopLoading());
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al obtener las novedades',
			});
		}
	};

	const Header = (novedad) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Icon
					margin='0 0.25em 0 0'
					color={!ESTADOS[novedad.leido] ? 'red' : 'green'}
					fs='1.25em'
					icon={PrimeIcons.EYE}
				/>{' '}
				- {novedad.fecha} - {novedad.titulo}
			</div>
		);
	};

	useEffect(() => {
		ListarNovedades();
	}, []);

	useEffect(() => {
		ListarNovedades();
	}, [consorcio]);

	return (
		<NovedadesWrapper>
			{novedades.length ? (
				<Accordion
					onTabOpen={async (e) => {
						const novedad = novedades[e.index];
						const wasRead = ESTADOS[novedad.leido];
						if (!wasRead) {
							const { Data, Success } = await marcarNovedadLeida(novedad.id);

							if (Success) {
								toast.current.show({
									severity: 'info',
									summary: 'La notificación fue marcada como leída',
								});
								const { Data, Success } = await obtenerNovedades(consorcio);
								if (Success) {
									setNovedades(Data);
								}
							}
						}
					}}
					style={{ width: '100%' }}>
					{novedades.map((novedad) => {
						return (
							<AccordionTab header={Header(novedad)}>
								<DetalleNovedad novedad={novedad} />
							</AccordionTab>
						);
					})}
				</Accordion>
			) : (
				<Empty message='No hay novedades para mostrar' />
			)}
		</NovedadesWrapper>
	);
};

export default Novedades;

import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import React, { useEffect, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { ToastContext } from './context/ToastContext';
import { Toast } from 'primereact/toast';
import { addLocale, locale } from 'primereact/api';
import { theme } from './utils/Theme';
import GlobalStyles, { AppWrapper } from './utils/GlobalStyles';
import RoutesApplication from './routes/RoutesApplication';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { useSelector } from 'react-redux';
import Loader from './components/Loader/Loader';

const App = () => {
	const toast = useRef(null);
	const { loader } = useSelector((state) => state);

	useEffect(() => {
		addLocale('es', {
			firstDayOfWeek: 0,
			dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
			dayNamesMin: ['D', 'L', 'Ma', 'Mi', 'J', 'V', 'S'],
			monthNames: [
				'Enero',
				'Febrero',
				'Marzo',
				'Abril',
				'Mayo',
				'Junio',
				'Julio',
				'Agosto',
				'Septiembre',
				'Octubre',
				'Noviembre',
				'Diciembre',
			],
			monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
			today: 'Hoy',
			clear: 'Limpiar',
		});
		locale('es');
	}, []);

	return (
		<ThemeProvider theme={theme}>
			{loader.isLoading && <Loader message={loader.message} />}
			<AppWrapper>
				<GlobalStyles />
				<ToastContext.Provider value={{ toast }}>
					<Toast ref={toast} />
					<BrowserRouter>
						<RoutesApplication />
					</BrowserRouter>
					<Footer />
				</ToastContext.Provider>
			</AppWrapper>
		</ThemeProvider>
	);
};

export default App;

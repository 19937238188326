import { AxiosInstance } from '../utils/AxiosInstance';

export const obtenerPedidosSugerencias = async ({ consorcio, usuario, mes, anio }) => {
	const { data: data1 } = await AxiosInstance.post(`/Servicios/ObtenerUsuario`);
	

	const { data } =
		await AxiosInstance.post(`/Servicios/PedidosListado?IdConsorcio=${consorcio}&_idUsuario=${usuario}&mes=${mes}&anio=${anio}
    `);
	return data;
};

export const obtenerTiposPedido = async () => {
	const { data } = await AxiosInstance.post(`/Servicios/ListadosTiposPedido`);
	return data;
};

export const enviarPedido = async ({
	IdConsorcio,
	TipoPedido,
	IdUnidadFuncionalCarga,
	IdUnidadFuncionalAfectada,
	TelefonoContacto,
	Comentarios,
}) => {
	const { data } = await AxiosInstance.post(
		`/Servicios/PedidosEnviar?IdConsorcio=${IdConsorcio}&TipoPedido=${TipoPedido}&IdUnidadFuncionalCarga=${IdUnidadFuncionalCarga}&IdUnidadFuncionalAfectada=${IdUnidadFuncionalAfectada}&TelefonoContacto=${TelefonoContacto}&Comentarios=${Comentarios}`,
	);

	return data;
};

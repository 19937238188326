import React from 'react';

import styled from 'styled-components';
import ButtonVEME from '../ButtonVEME/ButtonVEME';

const Wrapper = styled.div`
	width: 80%;
	height: 20%;
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	border-radius: 0.5em;
	padding: 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0.5em auto 0.75em auto;
`;

const Amenitie = ({ amenitie, selected, onClick }) => {
	return (
		<Wrapper>
			{amenitie?.descripcion}
			<ButtonVEME
				small
				onClick={onClick}
				secondary={selected}
				label={!selected ? 'Seleccionar' : 'Seleccionado'}
			/>
		</Wrapper>
	);
};

export default Amenitie;

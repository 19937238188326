import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Amenitie from '../../../components/Amenitie/Amenitie';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import Empty from '../../../components/Empty/Empty';
import Modal from '../../../components/Modal/Modal';
import Pill from '../../../components/Pill/Pill';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { obtenerAmenitiesConsorcio, obtenerReservasAmenities } from '../../../services/Amenities';
import { setColorByStatus } from '../../../utils/Functions';
import { HeaderAccordion } from '../Cobros/styles';
import DetalleReserva from './DetalleReserva';
import NuevaReserva from './NuevaReserva';
import {
	AmenitiesWrapper,
	BodyAmenities,
	HeaderAmenities,
	LeftAmenities,
	LeftBody,
	LeftBottom,
	RightAmenities,
} from './styles';

const Amenities = () => {
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);
	const { consorcio } = useSelector((state) => state.auth);

	const [modal, setModal] = useState({
		visible: false,
	});

	const [amenities, setAmenities] = useState([]);
	const [reservas, setReservas] = useState([]);

	const ListarAmenities = async () => {
		try {
			dispatch(startLoading('Obteniendo las reservas de amenities del consorcio'));

			const response1 = await obtenerReservasAmenities(consorcio);

			if (response1.Success) {
				setReservas(response1.Data);
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Hubo un error al obtener las reservas de amenities del consorcio',
				});
			}

			dispatch(startLoading('Obteniendo amenities del consorcio'));
			const response = await obtenerAmenitiesConsorcio(consorcio);
			if (response.Success) {
				setAmenities(response.Data);
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Hubo un error al obtener los amenities del consorcio',
				});
			}
			dispatch(stopLoading());
		} catch (error) {
			
			toast.current.show({
				severity: 'error',
				summary: error,
			});
			dispatch(stopLoading());
		}
	};

	const Header = (reserva) => {
		const fecha = reserva.fechaDesde;
		

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{reserva?.fechaDesde?.substring(0, 10)} -{reserva.unidadFuncional} - {reserva.amenity}
				<Pill color={setColorByStatus(reserva.estado)} status={reserva.estado} />
			</div>
		);
	};

	useEffect(() => {
		ListarAmenities();
	}, []);

	useEffect(() => {
		ListarAmenities();
	}, [consorcio]);

	return (
		<AmenitiesWrapper>
			<Modal
				footer={NaN}
				
				header='Nueva reserva'
				visible={modal.visible}
				onHide={() => setModal({ visible: false })}>
				<NuevaReserva
					ListarAmenities={ListarAmenities}
					onHide={() => setModal({ visible: false })}
					amenities={amenities}
				/>
			</Modal>
			<HeaderAmenities>
				<ButtonVEME label='Nueva reserva' onClick={(e) => setModal({ visible: true })} />
			</HeaderAmenities>
			<BodyAmenities>
				{reservas.length ? (
					<Accordion style={{ width: '100%' }}>
						{reservas.map((reserva) => {
							return (
								<AccordionTab header={Header(reserva)}>
									<DetalleReserva reserva={reserva} />
								</AccordionTab>
							);
						})}
					</Accordion>
				) : (
					<Empty message='No hay reservas para mostrar' />
				)}
			</BodyAmenities>
		</AmenitiesWrapper>
	);
};

export default Amenities;

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import LastExpense from '../../../components/LastExpense/LastExpense';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { getExpensas } from '../../../services/Expensas';
import { ExpensasWrapper, HistoryExpenses } from './styles';
import ExpensaDetalle from './ExpensaDetalle';
import Empty from '../../../components/Empty/Empty';

const Expensas = () => {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);
	const { toast } = useContext(ToastContext);
	const [expensas, setExpensas] = useState({
		Liquidaciones: [],
		Pagos: 0,
		SaldoAl: null,
		Vencimiento: null,
	});

	const meses = {
		'01': 'Enero',
		'02': 'Febrero',
		'03': 'Marzo',
		'04': 'Abril',
		'05': 'Mayo',
		'06': 'Junio',
		'07': 'Julio',
		'08': 'Agosto',
		'09': 'Septiembre',
		'10': 'Octubre',
		'11': 'Noviembre',
		'12': 'Diciembre',
	};

	const ObtenerExpensas = async () => {
		try {
			dispatch(startLoading('Obteniendo expensas'));
			const response = await getExpensas(auth.unidadfuncional);

			if (response.Success) {
				setExpensas(response.Data);
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Hubo un error al obtener las expensas',
				});
			}

			dispatch(stopLoading());
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al obtener las expensas',
			});
			dispatch(stopLoading());
		}
	};
	useEffect(() => {
		ObtenerExpensas();
	}, []);

	useEffect(() => {
		ObtenerExpensas();
	}, [auth.unidadfuncional]);
	return (
		<ExpensasWrapper>
			<LastExpense expense={expensas} />
			<HistoryExpenses>
				{expensas.Liquidaciones.length ? (
					<Accordion style={{ width: '100%' }}>
						{expensas.Liquidaciones.slice(1).map((liquidacion) => {
							const fecha = liquidacion?.FechaHasta.substr(0, 10);
							const mes = fecha.split('-')[1];
							const año = fecha.split('-')[0];

							return (
								<AccordionTab header={`${meses[mes]} ${año}`}>
									<ExpensaDetalle liquidacion={liquidacion} />
								</AccordionTab>
							);
						})}
					</Accordion>
				) : (
					<Empty message='No hay expensas para mostrar' />
				)}
			</HistoryExpenses>
		</ExpensasWrapper>
	);
};

export default Expensas;

import { InputSwitch } from 'primereact/inputswitch';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import InputVEME from '../../../components/InputVEME/InputVEME';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { obtenerUnidadesFuncionalesConsorcio } from '../../../services/Consorcio';
import { enviarPedido } from '../../../services/PedidosSugerencias';
import { InputContainer, ModalContainer, ModalRow } from './styles';

const NuevoPedido = ({ tiposPedido, consorcio, unidadfuncional, user, onHide }) => {
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);

	const [nuevoPedido, setNuevoPedido] = useState({
		TipoPedido: tiposPedido[0]?.Id,
		IdConsorcio: consorcio,
		IdUnidadFuncionalCarga: unidadfuncional,
		IdUnidadFuncionalAfectada: null,
		TelefonoContacto: user.DATOS_PERSONA?.[0]?.CELULAR,
		afectaOtraUnidad: false,
		Comentarios: '',
	});

	const [unidadesFuncionalesConsorcio, setUnidadesFuncionalesConsorcio] = useState([]);

	const onSubmit = async (e) => {
		e.preventDefault();
		dispatch(startLoading('Guardando pedido'));

		try {
			const response = await enviarPedido({
				...nuevoPedido,
				IdUnidadFuncionalAfectada: nuevoPedido.afectaOtraUnidad ? nuevoPedido.IdUnidadFuncionalAfectada : null,
			});
			if (response.Success) {
				toast.current.show({
					severity: 'success',
					summary: 'Pedido cargado con éxito',
				});
				setNuevoPedido({
					TipoPedido: tiposPedido[0]?.Id,
					IdConsorcio: consorcio,
					IdUnidadFuncionalCarga: unidadfuncional,
					IdUnidadFuncionalAfectada: null,
					TelefonoContacto: user.DATOS_PERSONA?.[0]?.CELULAR,
					afectaOtraUnidad: false,
					Comentarios: '',
				});
				onHide();
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Hubo un error al cargar el pedido',
				});
			}

			dispatch(stopLoading());
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al cargar el pedido',
			});
			dispatch(stopLoading());
		}
	};

	return (
		<ModalContainer onSubmit={onSubmit}>
			<ModalRow>
				<InputContainer>
					<InputVEME
						label='Tipo'
						isDropdown
						optionLabel='Descripcion'
						optionValue='Id'
						options={tiposPedido}
						value={nuevoPedido.TipoPedido}
					/>
				</InputContainer>
				<InputContainer>
					<InputVEME
						label='Nombre y apellido'
						disabled
						value={`${user?.DATOS_PERSONA?.[0]?.NOMBRE} ${user?.DATOS_PERSONA?.[0]?.APELLIDO}`}
					/>
				</InputContainer>{' '}
				<InputContainer style={{ display: 'flex', alignItems: 'center' }}>
					<InputSwitch
						checked={nuevoPedido.afectaOtraUnidad}
						style={{ marginRight: '0.5em' }}
						onChange={async (e) => {
							if (e.value && !unidadesFuncionalesConsorcio.length) {
								dispatch(startLoading('Obteniendo unidades funcionales'));
								const response = await obtenerUnidadesFuncionalesConsorcio(consorcio);
								if (response.Success) {
									if (!response.Data.length) {
										toast.current.show({
											severity: 'error',
											summary: 'El consorcio no cuenta con unidades funcionales disponbles',
										});
									}

									setUnidadesFuncionalesConsorcio(response.Data);
								} else {
									toast.current.show({
										severity: 'error',
										summary: 'Hubo un error al obtener las unidades funcionales del consorcio',
									});
								}
							}
							dispatch(stopLoading());
							setNuevoPedido({
								...nuevoPedido,
								afectaOtraUnidad: e.value,
							});
						}}
					/>
					<InputVEME
						disabled={!nuevoPedido.afectaOtraUnidad}
						label='Afecta a otra unidad funcional'
						isDropdown
						filter
						filterBy='Descripcion'
						options={unidadesFuncionalesConsorcio}
						optionValue={'Id'}
						optionLabel={'Descripcion'}
						onChange={(e) => setNuevoPedido({ ...nuevoPedido, IdUnidadFuncionalAfectada: e.value })}
						value={nuevoPedido.IdUnidadFuncionalAfectada}
					/>
				</InputContainer>
			</ModalRow>

			<ModalRow>
				<InputContainer>
					<InputVEME
						label='Telefono de contacto'
						value={nuevoPedido.TelefonoContacto}
						onChange={(e) => setNuevoPedido({ ...nuevoPedido, TelefonoContacto: e.target.value })}
					/>
				</InputContainer>
				<InputContainer width='65%'>
					<InputVEME
						label='Comentarios'
						value={nuevoPedido.Comentarios}
						isTextarea
						onChange={(e) =>
							setNuevoPedido({
								...nuevoPedido,
								Comentarios: e.target.value,
							})
						}
					/>
				</InputContainer>
			</ModalRow>
			<ModalRow style={{ display: 'flex', justifyContent: 'flex-end', margin: 0 }}>
				<ButtonVEME
					label='Enviar'
					disabled={
						Object.keys(nuevoPedido).filter((key) => {
							if (key === 'IdUnidadFuncionalAfectada') {
								if (nuevoPedido.afectaOtraUnidad) {
									return !nuevoPedido[key];
								}
							} else {
								if (key !== 'afectaOtraUnidad') {
									return !nuevoPedido[key];
								}
							}
						}).length
					}
				/>
			</ModalRow>
		</ModalContainer>
	);
};

export default NuevoPedido;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AdministracionWrapper, Label, NombreAdministracion, RowAdministracion, Value } from './styles';

const MiAdministracion = () => {
	const { ADMINISTRADOR } = useSelector((state) => state.auth.user);
	const [administracion, setAdministracion] = useState(null);
	useEffect(() => {
		setAdministracion(ADMINISTRADOR[0]);
	}, []);
	return (
		<AdministracionWrapper>
			<NombreAdministracion>{administracion?.RAZON_SOCIAL}</NombreAdministracion>
			<RowAdministracion>
				<Label>CUIT:</Label>
				<Value>{administracion?.CUIT}</Value>
			</RowAdministracion>
			<RowAdministracion>
				<Label>Responsable:</Label>
				<Value>
					{administracion?.NOMBRE_RESPONSABLE} {administracion?.APELLIDO_RESPONSABLE}
				</Value>
			</RowAdministracion>
			<RowAdministracion>
				<Label>Horarios de atención:</Label>
				<Value>{administracion?.HORARIO_ATENCION}</Value>
			</RowAdministracion>
			<RowAdministracion>
				<Label>Domicilio:</Label>
				<Value>{administracion?.DOMICILIO}</Value>
			</RowAdministracion>
			<RowAdministracion>
				<Label>Correo electrónico:</Label>
				<Value>{administracion?.EMAIL}</Value>
			</RowAdministracion>

			<RowAdministracion>
				<Label>Teléfono:</Label>
				<Value>{administracion?.TELEFONO}</Value>
			</RowAdministracion>
			<RowAdministracion>
				<Label>Teléfono de emergencia:</Label>
				<Value>{administracion?.TELEFONO_EMERGENCIA}</Value>
			</RowAdministracion>
		</AdministracionWrapper>
	);
};

export default MiAdministracion;

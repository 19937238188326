export const FormatDate = (date) => {
	if (!date) return;
	date.setHours(date.getHours() - 3);

	const ISO_DATE = new Date(date).toISOString();

	return ISO_DATE.split('T')[0].split('-').reverse().join('/');
};

export const setColorByStatus = (status) => {
	switch (status) {
		case 'ANULADO':
		case 'CANCELADO':
		case 'RECHAZADO':
			return 'red';
		case 'APROBADO':
		case 'CONFIRMADA':
			return 'green';
		case 'PENDIENTE':
		case 'GENERADA':
			return 'yellow';
		default:
			return 'shadow50';
	}
};

export const Capitalize = (string) => {
	const FirstLetter = string[0];
	const Rest = string.substring(1);

	return `${FirstLetter.toUpperCase()}${Rest.toLowerCase()}`.trim();
};

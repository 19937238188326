import { AxiosInstance } from '../utils/AxiosInstance';
import { FormatDate } from '../utils/Functions';

export const obtenerCobros = async ({ consorcio, unidadfuncional }) => {
	const { data } = await AxiosInstance.post(
		`/Servicios/CobrosListar?IdConsorcio=${consorcio}&IdUnidadFuncional=${unidadfuncional}`,
	);

	return data;
};

export const obtenerFormasPago = async ({ consorcio }) => {
	const { data } = await AxiosInstance.post(`/Servicios/ListadosFormasDePago?idConsorcio=${consorcio}`);
	return data;
};

export const informarNuevoPago = async ({
	consorcio,
	unidadfuncional,
	Fecha,
	IdFormaPago,
	NroComprobante,
	Monto,
	Comentarios,
	Archivo,
}) => {
	let body;
	if (Archivo) {
		body = new FormData();
		body.append('data', Archivo);
	}
	const { data } = await AxiosInstance.post(
		`/Servicios/CobrosInformar?IdConsorcio=${consorcio}&IdUnidadFuncional=${unidadfuncional}&Fecha=${FormatDate(
			new Date(Fecha),
		)}&IdFormaPago=${IdFormaPago}&NroComprobante=${NroComprobante}&Monto=${Monto}&Comentarios=${
			Comentarios ? Comentarios : ''
		}`,
		body,
	);
	return data;
};

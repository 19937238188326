import styled from 'styled-components';

export const WrapperPedidosSugerencias = styled.div`
	width: 100%;
	height: 100%;
`;

export const HeaderPedidosSugerencias = styled.div`
	width: 100%;
	height: 15%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const HistoryPedidosSugerencias = styled.div`
	width: 100%;
	height: 80%;
	overflow: auto;
`;

export const InputContainer = styled.div`
	width: ${(props) => props.width || '30%'};
`;

export const ModalContainer = styled.form`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

export const ModalRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 2em 0;
`;

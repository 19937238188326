import { RECEIVE_USER_DATA, LOGIN_FAILED, LOGOUT, SET_NEW_UF } from './auth.actions';

const INITIAL_STATE = {
	user: null,
	unidadfuncional: null,
	consorcio: null,
	usuario: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case LOGIN_FAILED:
			return {
				...state,
				error: payload,
			};
		case RECEIVE_USER_DATA:
			return {
				...state,
				user: payload,
				unidadfuncional: payload?.UNIDADES_FUNCIONALES?.[0]?.UnidadFuncionalId,
				consorcio: payload?.UNIDADES_FUNCIONALES?.[0]?.ConsorcioId,
				usuario: payload?.UNIDADES_FUNCIONALES?.[0]?.PersonaId,
			};
		case LOGOUT:
			return {
				...state,
				user: null,
			};
		case SET_NEW_UF:
			return {
				...state,
				unidadfuncional: payload.UnidadFuncionalId,
				consorcio: payload.ConsorcioId,
			};
		default:
			return state;
	}
};

export default authReducer;

import React from 'react';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const InputTextStyled = styled(InputText)`
	width: 100% !important;
	border-bottom: 2.5px solid ${(props) => props.theme.main} !important;

	&:focus {
		box-shadow: none !important;
		border-bottom: 2px solid ${(props) => props.theme.secondary} !important;
	}
`;

const InputTextareaStyled = styled(InputTextarea)`
	width: 100% !important;
	border-bottom: 2.5px solid ${(props) => props.theme.main} !important;

	&:focus {
		box-shadow: none !important;
		border-bottom: 2px solid ${(props) => props.theme.secondary} !important;
	}
`;

const InputSwitchStyled = styled(InputSwitch)`
	& ~ label {
		top: -0.75rem !important;
		font-size: 0.75em !important;
	}
`;

const InputNumberStyled = styled(InputNumber)`
	width: 100% !important;
	& > input {
		box-shadow: none !important;
		border-bottom: 2px solid ${(props) => props.theme.main} !important;
		&:focus {
			box-shadow: none !important;
			border-bottom: 2px solid ${(props) => props.theme.secondary} !important;
		}
	}
	&:focus {
		box-shadow: none !important;
	}
`;
const InputCalendarStyled = styled(Calendar)`
	width: 100% !important;
	& > input {
		box-shadow: none !important;
		border-bottom: 2px solid ${(props) => props.theme.main} !important;
		&:focus {
			box-shadow: none !important;
			border-bottom: 2px solid ${(props) => props.theme.secondary} !important;
		}
	}
`;
const InputDropdownStyled = styled(Dropdown)`
	width: 100% !important;
	border-bottom: 2px solid ${(props) => props.theme.main} !important;
	& > * > * {
		box-shadow: none !important;
	}
	&:focus {
		box-shadow: none !important;
	}
`;

const monthNavigatorTemplate = (e) => {
	return (
		<Dropdown
			value={e.value}
			options={e.options}
			onChange={(event) => e.onChange(event.originalEvent, event.value)}
			style={{ lineHeight: 1 }}
		/>
	);
};

const yearNavigatorTemplate = (e) => {
	return (
		<Dropdown
			value={e.value}
			options={e.options}
			onChange={(event) => e.onChange(event.originalEvent, event.value)}
			className='ml-2'
			style={{ lineHeight: 1 }}
		/>
	);
};

const InputVEME = ({
	label,
	isDropdown,
	isSwitch,
	isCalendar,
	isNumber,
	isTextarea,
	onClickIcon,
	placeholder,
	...rest
}) => {
	return (
		<span
			style={{ flex: 1 }}
			className={`p-float-label ${rest.righticon && 'p-input-icon-right'} ${
				rest.lefticon && 'p-input-icon-left'
			}`}>
			{rest.lefticon || rest.righticon ? <i onClick={onClickIcon} className={`pi ${rest.icon}`} /> : ''}
			{isNumber ? (
				<InputNumberStyled placeholder={placeholder} id={label} {...rest} />
			) : isDropdown ? (
				<InputDropdownStyled emptyMessage='No hay opciones disponibles' id={label} {...rest} />
			) : isCalendar ? (
				<InputCalendarStyled
					yearNavigatorTemplate={yearNavigatorTemplate}
					monthNavigatorTemplate={monthNavigatorTemplate}
					dateFormat="dd/mm/yy"
					placeholder={placeholder}
					id={label}
					{...rest}
				/>
			) : isTextarea ? (
				<InputTextareaStyled placeholder={placeholder} id={label} {...rest} autoResize rows={3} />
			) : isSwitch ? (
				<InputSwitchStyled placeholder={placeholder} id={label} {...rest} />
			) : (
				<InputTextStyled placeholder={placeholder} id={label} {...rest} />
			)}
			<label htmlFor='label'>{label}</label>
		</span>
	);
};

export default InputVEME;

import styled from 'styled-components';

export const CobrosWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: 700px) {
		height: auto;
	}
`;

export const HistoryCobros = styled.div`
	height: 80%;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: 700px) {
		padding: 0;
		font-size: 2em;
	}
`;

export const HeaderCobros = styled.div`
	height: 15%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const HeaderAccordion = styled.div`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.main} !important;
	@media screen and (max-width: 700px) {
		align-items: left;
	}
`;

export const InformarPagoFormulario = styled.form`
	width: 100%;
	height: 100%;
`;

export const RowPagoFormulario = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: ${(props) => props.margin || '0em 0em 3em 0'};
`;

export const InputContainer = styled.div`
	width: 45%;
`;

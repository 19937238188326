import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PrimeIcons } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import TooltipVEME from '../TooltipVEME/TooltipVEME';
const NavigatorWrapper = styled.div`
	width: 50%;
	height: 5em;
	background-color: ${(props) => props.theme.white};
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	display: flex;
	border-radius: 0.5em;
	justify-content: space-evenly;
	align-items: center;
	@media screen and (max-width: 700px) {
		width: 80%;
		border-radius: 0.5em;
		height: 3.5em;
	}
`;

const ModuleWrapper = styled(Link)`
	height: 90%;
	width: 10%;
	transition: 0.2s;
	border-radius: 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.secondary};
	background-color: ${(props) => props.selected && props.theme.secondary};
	color: ${(props) => props.selected && props.theme.white};

	${(props) => props.disabled && `color:${props.theme.shadow50};`}
	${(props) => props.disabled && `cursor:not-allowed;`}
	&:hover {
		transition: 0.2s;
		background-color: ${(props) => props.theme.secondary};
		${(props) => props.disabled && `background-color:${props.theme.shadow50};`}
		color: ${(props) => props.theme.white};
	}
`;

const Divider = styled.div`
	width: 0.25%;
	height: 100%;
	background-color: ${(props) => props.theme.main50};
`;

const Icon = styled.i`
	font-size: 2em;
`;

const MODULES = [
	{
		icon: PrimeIcons.BOOK,
		path: '/principal/expensas',
		name: 'Mis expensas',
		tooltip: 'tooltip-misexpensas',
		disabled: false,
	},
	{
		icon: PrimeIcons.MONEY_BILL,
		path: '/principal/cobros',
		name: 'Cobros',
		disabled: false,
		tooltip: 'tooltip-cobros',
	},
	{
		icon: PrimeIcons.VOLUME_OFF,
		path: '/principal/novedades',
		name: 'Novedades',
		tooltip: 'tooltip-novedades',
		disabled: false,
	},
	{
		icon: PrimeIcons.SHIELD,
		path: '/principal/mi-administracion',
		name: 'Mi administración',
		tooltip: 'tooltip-miadministracion',
		disabled: false,
	},
	{
		icon: PrimeIcons.HOME,
		path: '/principal/amenities',
		name: 'Amenities',
		tooltip: 'tooltip-amenities',
		disabled: false,
	},
	{
		icon: PrimeIcons.CALENDAR,
		path: '/principal/calendario-consorcio',
		name: 'Calendario de consorcio',
		tooltip: 'tooltip-calendarioconsorcio',
		disabled: false,
	},
	{
		icon: PrimeIcons.COMMENTS,
		path: '/principal/pedidos-sugerencias',
		name: 'Pedidos y sugerencias',
		tooltip: 'tooltip-pedidossurgerencias',
		disabled: false,
	},
];

const ModuleNavigator = () => {
	const location = useLocation();
	return (
		<NavigatorWrapper>
			{MODULES.map((module, index) =>
				index !== MODULES.length - 1 ? (
					<>
						<TooltipVEME target={`.${module.tooltip}`} />
						<ModuleWrapper
							disabled={module.disabled}
							selected={location.pathname === module.path}
							data-pr-tooltip={module.name}
							className={module.tooltip}
							to={!module.disabled && module.path}>
							<Icon className={module.icon} />
						</ModuleWrapper>
						<Divider />
					</>
				) : (
					<>
						<TooltipVEME target={`.${module.tooltip}`} />
						<ModuleWrapper
							disabled={module.disabled}
							selected={location.pathname === module.path}
							data-pr-tooltip={module.name}
							className={module.tooltip}
							to={module.path}>
							<Icon className={module.icon} />
						</ModuleWrapper>
					</>
				),
			)}
		</NavigatorWrapper>
	);
};

export default ModuleNavigator;

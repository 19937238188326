import { AxiosInstance } from '../utils/AxiosInstance';

export const getEstadoConsorcio = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/EstadoAnimoObtener?IdConsorcio=${ID}`);
	return data;
};

export const obtenerUnidadesFuncionalesConsorcio = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/ObtenerUnidadesDelConsorcio?IdConsorcio=${ID}`);
	return data;
};

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormatDate } from '../../utils/Functions';
import { PrimeIcons } from 'primereact/api';
import { Link } from 'react-router-dom';
import { ToastContext } from '../../context/ToastContext';
const ExpenseWrapper = styled.div`
	width: 100%;
	height: 20%;
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	border-radius: 0.5em;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ExpenseRow = styled.div`
	width: 95%;
	height: 50%;
	border-bottom: ${(props) => props.hasBorder && `1px solid ${props.theme.main50}`};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LabelRow = styled.span`
	color: ${(props) => props.theme.main};
	font-size: 1.1em;
	font-weight: 500;
`;

const ValueRow = styled.span`
	color: ${(props) => (props.value < 0 ? props.theme.main : props.theme.red)};
	font-size: ${(props) => props.fs || '1.25em'};
	font-weight: 600;
	display: flex;
	align-items: center;
`;

const IconExpense = styled.i`
	font-size: 20px;
	color: ${(props) => props.theme.secondary};
	margin-left: 0.5em;
	cursor: pointer;
`;

const LastExpense = ({ expense }) => {
	const { toast } = useContext(ToastContext);
	const [fechas, setFechas] = useState({
		desde: new Date(),
		hasta: new Date(),
	});

	useEffect(() => {
		if (expense?.Liquidaciones[0]) {
			setFechas({
				desde: new Date(expense?.Liquidaciones?.[0]?.FechaDesde),
				hasta: new Date(expense?.Liquidaciones?.[0]?.FechaHasta),
			});
		}
	}, []);
	return (
		<ExpenseWrapper>
			<ExpenseRow hasBorder>
				<LabelRow>Última liquidación:</LabelRow>
				<ValueRow fs='1em' value={-1}>
					{FormatDate(fechas.desde)} hasta {FormatDate(fechas.hasta)}
					<IconExpense
						as={'a'}
						onClick={() => {
							toast.current.show({
								severity: 'info',
								summary: 'La descarga puede tardar unos segundos',
							});
						}}
						className={PrimeIcons.FILE_PDF}
						href={expense?.Liquidaciones[0]?.URL_REPORTE}
					/>
				</ValueRow>
			</ExpenseRow>
			<ExpenseRow>
				<LabelRow>Saldo a pagar al {expense.SaldoAl || FormatDate(new Date())}:</LabelRow>
				<ValueRow value={expense.SaldoAPagar}>
					${expense.SaldoAPagar || 0}
					<IconExpense className={PrimeIcons.MONEY_BILL} />
				</ValueRow>
			</ExpenseRow>
		</ExpenseWrapper>
	);
};

export default LastExpense;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Amenities from '../views/Dashboard/Amenities/Amenities';
import Calendario from '../views/Dashboard/Calendario/Calendario';
import Cobros from '../views/Dashboard/Cobros/Cobros';
import Expensas from '../views/Dashboard/Expensas/Expensas';
import MiAdministracion from '../views/Dashboard/Mi administracion/MiAdministracion';
import Novedades from '../views/Dashboard/Novedades/Novedades';
import PedidosSugerencias from '../views/Dashboard/PedidosSugerencias/PedidosSugerencias';

const RoutesDashboard = () => {
	return (
		<Routes>
			<Route path='expensas' element={<Expensas />} />
			<Route path='cobros' element={<Cobros />} />
			<Route path='novedades' element={<Novedades />} />
			<Route path='mi-administracion' element={<MiAdministracion />} />
			<Route path='amenities' element={<Amenities />} />
			<Route path='calendario-consorcio' element={<Calendario />} />
			<Route path='pedidos-sugerencias' element={<PedidosSugerencias />} />
		</Routes>
	);
};

export default RoutesDashboard;

import React from 'react';
import styled from 'styled-components';
import { FormatDate } from '../../../utils/Functions';

const EventoDetalleWrapper = styled.div`
	width: 95%;
	height: 5em;
	background-color: ${(props) => props.theme.white};
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	display: flex;
	border-radius: 0.5em;
	flex-direction: column;
	justify-content: center;
`;

export const RowDetalle = styled.div`
	width: 100%;
	height: 40%;
	display: flex;
	color: ${(props) => props.theme.main};
	align-items: center;
	justify-content: center;
`;

export const Titulo = styled.span`
	font-size: 1.25em;
	font-weight: bold;
`;

export const Descripcion = styled.span`
	font-size: 0.85em;
`;

const EventoDetalle = ({ evento }) => {
	return (
		<EventoDetalleWrapper>
			<RowDetalle>
				<Titulo>
					{evento.fecha} {evento.hora} | {evento.titulo}
				</Titulo>
			</RowDetalle>
			<RowDetalle>
				<Descripcion>{evento.descripcion}</Descripcion>
			</RowDetalle>
		</EventoDetalleWrapper>
	);
};

export default EventoDetalle;

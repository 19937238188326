import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	ButtonContainer,
	InputContainer,
	LoginFormContainer,
	LoginWrapper,
	LogoImage,
	NavigateBetweenAuth,
	WrapperLogoAuthentication,
} from './styled';
import logo from '../../assets/images/logo.svg';
import InputVEME from '../../components/InputVEME/InputVEME';
import ButtonVEME from '../../components/ButtonVEME/ButtonVEME';
import { useDispatch } from 'react-redux';
import { IniciarSesion } from '../../redux/auth/auth.actions';
import { startLoading, stopLoading } from '../../redux/loader/loader.actions';
import { useMediaQuery } from 'react-responsive'



const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [credentials, setCredentials] = useState({
		username: '',
		password: '',
		error: '',
	});

	const [showPasswrod, setShowPassword] = useState(false);

	const onSubmit = async (e) => {
		e.preventDefault();
		dispatch(startLoading('Iniciando sesión'));
		try {
			const response = await dispatch(IniciarSesion(credentials));
			dispatch(stopLoading());
			if (response?.DATOS_PERSONA) {
				
				navigate('/principal');
			}
		} catch ({ Error }) {
			
		}
	};

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	const Username = ({ children }) => {
		const isUsername = useMediaQuery({ minWidth: 992 })
		return isUsername ? children : null
	  }
	  const Password = ({ children }) => {
		const isPassword = useMediaQuery({ minWidth: 768, maxWidth: 991 })
		return isPassword ? children : null
	  }
	  const Mobile = ({ children }) => {
		const isMobile = useMediaQuery({ maxWidth: 767 })
		return isMobile ? children : null
	  }
	  const Default = ({ children }) => {
		const isNotMobile = useMediaQuery({ minWidth: 768 })
		return isNotMobile ? children : null
	  }
	  
	  const Example = () => (
		<div>
		  <Username>nombre de usuario</Username>
		  <Password>Tablet</Password>
		  <Mobile>Mobile</Mobile>
		  <Default>Not mobile (desktop or laptop or tablet)</Default>
		</div>
	  )
	  
	return (
		<LoginWrapper onSubmit={onSubmit}>
			<WrapperLogoAuthentication>
				<LogoImage src={logo} />
			</WrapperLogoAuthentication>
			<LoginFormContainer>
				<InputContainer>
					<InputVEME
						name={'username'}
						label={'Nombre de usuario'}
						value={credentials.username}
						onChange={onChange}
					/>
				</InputContainer>{' '}
				<InputContainer>
					<InputVEME
						name={'password'}
						label={'Contraseña'}
						value={credentials.password}
						onChange={onChange}
						type={!showPasswrod ? 'password' : 'text'}
						righticon
						icon={!showPasswrod ? 'pi-eye' : 'pi-eye-slash'}
						onClickIcon={() => setShowPassword(!showPasswrod)}
					/>
				</InputContainer>
			</LoginFormContainer>
			<ButtonContainer>
				<ButtonVEME
					height='35%'
					label={'Iniciar sesión'}
					disabled={
						Object.keys(credentials)
							.filter((key) => key !== 'error')
							.filter((v) => !credentials[v]).length
					}
				/>
				<NavigateBetweenAuth to='/recuperar-credenciales'>¿Olvidaste tu contraseña?</NavigateBetweenAuth>
			</ButtonContainer>
		</LoginWrapper>
	);
};



export default Login;

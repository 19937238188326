import React from 'react';
import styled from 'styled-components';
const ContainerPill = styled.div`
	padding: 0.5em 1.75em;
	border-radius: 5em;
	margin: 0 0.5em;
	background-color: ${(props) => props.theme[props.color] || props.theme.shadow50};
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.75em;
	
`;

const Pill = ({ status, color }) => {
	return <ContainerPill color={color}>{status}</ContainerPill>;
};

export default Pill;

import { AxiosInstance } from '../utils/AxiosInstance';

export const obtenerNovedades = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/NovedadesListar?IdConsorcio=${ID}`);

	return data;
};

export const marcarNovedadLeida = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/NovedadesMarcarComoLeida?IdNovedad=${ID}`);

	return data;
};

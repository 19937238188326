import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import Empty from '../../../components/Empty/Empty';
import Modal from '../../../components/Modal/Modal';
import Pill from '../../../components/Pill/Pill';
import { ToastContext } from '../../../context/ToastContext';
import { Logout } from '../../../redux/auth/auth.actions';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { obtenerCobros } from '../../../services/Cobros';
import { setColorByStatus } from '../../../utils/Functions';
import DetalleCobro from './DetalleCobro';
import InformarPago from './InformarPago';
import { CobrosWrapper, HeaderAccordion, HeaderCobros, HistoryCobros } from './styles';

const Cobros = () => {
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);
	const { consorcio, unidadfuncional } = useSelector((state) => state.auth);
	const [cobros, setCobros] = useState([]);
	const [modal, setModal] = useState({
		visible: false,
	});
	const ListarCobros = async () => {
		try {
			dispatch(startLoading('Obteniendo listado de cobros'));
			const { Data, Success } = await obtenerCobros({
				consorcio,
				unidadfuncional,
			});
			if (Success) {
				setCobros(Data);
			}
			dispatch(stopLoading());
		} catch ({ Error }) {
			if (Error === 'El token se encuentra inactivo.') {
				dispatch(Logout());
			}

			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al obtener los cobros',
			});
			dispatch(stopLoading());
		}
	};
	useEffect(() => {
		ListarCobros();
	}, [unidadfuncional, consorcio]);

	const Header = (cobro) => {
		return (
			<HeaderAccordion style={{ display: 'flex', alignItems: 'center' }}>
				{cobro.fecha} - {cobro.monto} - <Pill color={setColorByStatus(cobro.estado)} status={cobro.estado} />
			</HeaderAccordion>
		);
	};

	return (
		<CobrosWrapper>
			<Modal footer={NaN} width='50vw' visible={modal.visible} onHide={() => setModal({ visible: false })}>
				<InformarPago setModal={setModal} ListarCobros={ListarCobros} />
			</Modal>
			<HeaderCobros>
				<ButtonVEME height='60%' label='Informar pago' onClick={() => setModal({ visible: true })} />
			</HeaderCobros>
			<HistoryCobros>
				{cobros.length ? (
					<Accordion style={{ width: '100%' }}>
						{cobros.map((cobro) => {
							return (
								<AccordionTab header={Header(cobro)}>
									<DetalleCobro cobro={cobro} />
								</AccordionTab>
							);
						})}
					</Accordion>
				) : (
					<Empty message='No hay cobros para mostrar' />
				)}
			</HistoryCobros>
		</CobrosWrapper>
	);
};

export default Cobros;

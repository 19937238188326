import React, { useContext, useState } from 'react';
import {
	ButtonContainer,
	InputContainer,
	LogoImage,
	NavigateBetweenAuth,
	RecoverFormWrapper,
	RecoverPasswordWrapper,
	WrapperLogoAuthentication,
} from './styled';
import logo from '../../assets/images/logo.svg';
import ButtonVEME from '../../components/ButtonVEME/ButtonVEME';
import InputVEME from '../../components/InputVEME/InputVEME';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/loader/loader.actions';
import { regenerarContrasenia } from '../../services/Auth';
import { ToastContext } from '../../context/ToastContext';

const RecoverPassword = () => {
	const [email, setEmail] = useState('');
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);

	const onSubmit = async (e) => {
		e.preventDefault();
		dispatch(startLoading('Regenerando contraseña'));
		try {
			const response = await regenerarContrasenia(email);
			if (response.Success) {
				toast.current.show({
					severity: 'success',
					summary: response.Message,
				});
				setEmail('');
			} else {
				toast.current.show({
					severity: 'error',
					summary: response.Error,
				});
			}
			dispatch(stopLoading());
		} catch ({ Error }) {
			toast.current.show({
				severity: 'error',
				summary: Error,
			});
			dispatch(stopLoading());
		}
	};

	return (
		<RecoverPasswordWrapper onSubmit={onSubmit}>
			<WrapperLogoAuthentication>
				<LogoImage src={logo} />
			</WrapperLogoAuthentication>
			<RecoverFormWrapper>
				<InputContainer>
					<InputVEME label={'Correo electrónico'} value={email} onChange={(e) => setEmail(e.target.value)} />
				</InputContainer>
			</RecoverFormWrapper>

			<ButtonContainer>
				<ButtonVEME height='40%' disabled={!email} label={'Recuperar credenciales'} />
				<NavigateBetweenAuth to='/iniciar-sesion'>Quiero iniciar sesión</NavigateBetweenAuth>
			</ButtonContainer>
		</RecoverPasswordWrapper>
	);
};

export default RecoverPassword;
